import React, { useContext } from 'react';
import UploadGuide from '../upload-guide';
import Gallery from '../gallery';
import { MyContext } from '../../App';

const GenerateImage = () => {
  const { step } = useContext(MyContext);
  return (
    <div>
      {
        step === 0 ? <UploadGuide />
          : step === 1 && <Gallery />
      }
    </div>
  );
}

export default GenerateImage;
