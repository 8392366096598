import React, { useContext } from 'react';
import beforeImg from './../../assets/images/before.png';
import afterImg from './../../assets/images/after.png';
import { useNavigate } from 'react-router-dom';
import LayOut from '../../layout';
import { MyContext } from '../../App';
import { SERVER } from '../../config/site';

const Home = () => {
  const { setProgress, setUploadedImage, setGender, setStep } = useContext(MyContext)
  const navigate = useNavigate();

  const uploadHandler = (files) => {
    if ( document.getElementById('terms').checked === false ) {
      alert('Please accept the terms and conditions to use this service.');
      return;
    } else {
      // const gender = document.getElementById("gender-select").selectedOptions[0].value;
      const formData = new FormData();
      formData.append('file', files[0]);
      const url = SERVER + '/upload-image';
      fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(res => {
          if (res.img) {
            setGender(document.getElementById("gender-select").selectedOptions[0].value);
            setUploadedImage(res.img);
            setStep(0);
            navigate('/generate-image');
          }
        });
    }
  };

  return (
    <LayOut>
      <div className='home-container'>
        <div className='home-left'>
          <p className="eyebrow">Become a</p>
          <h1>Cyber<br /><span>hero</span></h1>
          <p className="intro">Create your own unique AI generated Hero Illustrations!</p>
        </div>
        <div className='home-right'>
          <div className='home-right-inner'>
            <div className='home-before'>
              <p>Before</p>
              <img alt='Before' width='184' height='280' src={beforeImg} />
            </div>
            <div className='home-after'>
              <p>After</p>
              <img alt='After' width='184' height='280' src={afterImg} />
            </div>
          </div>
          <div className="gender-select-wrap">
            <select className="gender-select" id="gender-select" defaultValue="default">
              <option value="default" disabled>Please select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="none">Prefer not to say</option>
            </select>
          </div>
          <div className="home-right-footer">
            <label className="btn btn-primary">
              Upload image
              <input
                type='file'
                accept='image/*'
                onChange={(e) => uploadHandler(e.target.files)}
              />
            </label>
            <label className="terms-wrap" htmlFor='terms'>
              <input type="checkbox" name="terms" id="terms" />
              <p>I accept the <a href="https://docs.midjourney.com/docs/terms-of-service" target="_blank" rel="noreferrer">Midjourney Terms of Service</a> with whom I directly contract for the provision of this service (“Midjourney”). This service will include the transfer to and processing of my image file by Midjourney and its subcontractors. I acknowledge that Midjourney and its subcontractors are located outside the EU, in countries which may not have a statutory level of privacy which is deemed “adequate” under EU privacy laws. Upon completion of the service, my image files stored by Midjourney will automatically be deleted. Until completion, I can withdraw my consent by contacting takedown@midjourney.com</p>
            </label>
          </div>
        </div>
      </div>
    </LayOut>
  );
};

export default Home;
