import React, { useContext } from 'react';
import LayOut from '../../layout';
import { MyContext } from '../../App';
import UploadIcon from './../../assets/svg/upload.svg';
import DownloadIcon from './../../assets/svg/download.svg';

const Gallery = () => {
  const { generatedImages } = useContext(MyContext);
  return (
    <LayOut>
      <div className='generated-imgs-container'>
        {!generatedImages &&
          <div className='scanning-message'>
            <h2>Scanning and generating...</h2>
            <p>Please be patient whilst we transform you into a cyberhero!<br />This process can take up to 2 minutes.</p>
          </div>
        }
        {
          generatedImages &&
          <div className='generate-imgs-wrap'>
              <h2>Results</h2>
              <div className='generated-imgs'>
                  {generatedImages.map((item, index) => {
                    return (
                      <a href={item} className='generated-img' key={index} target="_blank" rel="noreferrer">
                          <img src={item} width="216" height="385" />
                          <span href={item} className='download'>
                            <img width="9" height="12" src={DownloadIcon} />
                            Download
                          </span>
                      </a>
                    );
                  })}
              </div>
          </div>
        }
        <div className='footer'>
          <a href="/" className='btn btn-white'>
            <img alt='Upload icon' width='13' height='16' src={UploadIcon} />
            Reupload
          </a>
          <a href="https://humanfirewallconference.com/" target="_blank" rel="noreferrer" className='btn btn-primary'>Sign up for HuFiCon 2024</a>
        </div>
      </div>
    </LayOut>
  );
};

export default Gallery;
