import { SERVER } from '../config/site';

const checkImage = (msgid) => {
  return fetch(SERVER + '/check-image?id=' + msgid)
    .then(res => res.json())
    .then(res => {
      return { status: res.status, image: res.task_result.image_url, images: res.task_result.image_urls, result: res }
    });
};

export default checkImage;
