import React from 'react';
import Logo from './../../assets/svg/logo.svg';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div id='navbar'>
      <div className='logo'>
        <img alt='SoSafe logo' width='161' height='40' src={Logo} onClick={() => navigate('/')} />
        Avatar Maker
      </div>
    </div>
  );
};

export default Navbar;
