import React from 'react';
import bgImage from './../assets/images/page-bg.jpg';
import { Navbar } from '../components';

const LayOut = ({ children }) => {
  return (
    <div>
      <div className='layout-navbar'>
        <Navbar />
      </div>
      <div className='layout-data'>{children}</div>
      <img alt='Background' src={bgImage} className='bg-img' style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', zIndex: '-1', objectFit: 'cover' }} />
    </div>
  );
};

export default LayOut;
