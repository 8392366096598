import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadIcon from './../../assets/svg/upload.svg';
import UploadGuide1 from './../../assets/images/upload-guide-1.png';
import UploadGuide2 from './../../assets/images/upload-guide-2.png';
import UploadGuide3 from './../../assets/images/upload-guide-3.png';
import UploadGuide4 from './../../assets/images/upload-guide-4.png';
import CheckIcon from './../../assets/svg/check.svg';
import CrossIcon from './../../assets/svg/cross.svg';
import LayOut from '../../layout';
import { MyContext } from '../../App';
import { SERVER } from '../../config/site';
import checkImage from '../../utils/checkImage';

const UploadGuide = () => {
  const navigate = useNavigate();
  // const [details, setDetails] = useState(true);
  const { uploadedImage, setgeneratedImage, setgeneratedImages, gender, setGender, setStep } = useContext(MyContext);

  const List = [
    {
      img: UploadGuide1,
      title: 'Front & clear',
      check: true,
    },
    {
      img: UploadGuide2,
      title: 'One side',
      check: false,
    },
    {
      img: UploadGuide3,
      title: 'No sunglasses',
      check: false,
    },
    {
      img: UploadGuide4,
      title: 'Blurry',
      check: false,
    },
  ];

  useEffect(() => {
    if (!uploadedImage) {
      navigate('/');
    }
  }, []);

  if (!gender) {
    alert('no gender!')
    return;
  }

  const genderPrompt = ', imagine an illustration of a traditional superhero wearing a superhero outfit, they have strong and charismatic and intelligent facial features, technically advanced cyberarmor, cinematic lighting, detailed, illustration --ar 9:16 --no blazer shirt jacket --fast --v 5.2 --seed 3297556480';
  if( gender === 'male' ) {
    const genderPrompt = ', imagine an illustration of a traditional male superhero wearing a superhero outfit, they have strong and charismatic and intelligent facial features, technically advanced cyberarmor, cinematic lighting, detailed, illustration --ar 9:16 --no blazer shirt jacket --fast --v 5.2 --seed 3297556480';
  }
  if( gender === 'female' ) {
    const genderPrompt = ', imagine an illustration of a traditional female superhero wearing a superhero outfit, they have strong and charismatic and intelligent facial features, technically advanced cyberarmor, cinematic lighting, detailed, illustration --ar 9:16 --no blazer shirt jacket --fast --v 5.2 --seed 3297556480';
  }

  const generateImage = () => {
    console.log('generateImage');
    fetch(SERVER + '/generate-image', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        prompt: uploadedImage + genderPrompt
      })
    })
      .then(res => res.json())
      .then(body => {
        console.log(body);

        if (body.status === 'success') {
          setStep(1);
          console.log('imagine API started');

          let generationInterval = setInterval(() => {
            const imagePromise = checkImage(body.task_id);
            console.log('task_id = ' + body.task_id);

            imagePromise.then((result) => {
              console.log(result);
              if (result.status === 'finished') {
                console.log(result);
                clearInterval(generationInterval);
                setgeneratedImage(result.image);
                setgeneratedImages(result.images);
              }
            });
          }, 5000);
        }
        if (body.status === 'failed') {
          console.log('failed');
        }
      });
  };

  return (
    <LayOut>
      <div className='upload-guide-container'>
        <div className='upload-guide-left'>
          <div className='uploaded-img-wrap'>
            <img className='uploaded-img' alt='Uploaded image' src={uploadedImage} width="213" height="320" />
            <div className='uploaded-img-btns'>
              <button
                className='btn btn-primary'
                onClick={generateImage}
              >
                Next
              </button>
              <label className='reupload-button'>
                <img alt='Upload icon' width='13' height='16' src={UploadIcon} />
                Reupload
                <input type='file' accept='image/*' />
              </label>
            </div>
          </div>
        </div>
        <div className='upload-guide-right'>
          <div className='upload-guide-right-header'>
            <h2>Upload<br /><span>Guide</span></h2>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</p>
          </div>
          <div className='upload-guide-images'>
            {List.map((item, index) => {
              return (
                <div className='upload-guide-img-wrap' key={index}>
                  <img className='upload-guide-img' alt='' src={item.img} />
                  <div className='upload-guide-content'>
                    <p>{item.title}</p>
                    <img width='26' height='26' alt={item.check ? 'Check' : 'Cross'} src={item.check ? CheckIcon : CrossIcon} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </LayOut>
  );
};

export default UploadGuide;
